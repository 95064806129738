import Api from "@/services/api";
import {removeEmptyParams, downloadData} from "@utils";
import moment from "moment";

export default {
    findAll(filter) {
        return Api().get("/linksheets", {params: removeEmptyParams(filter)}).then(res => {
            res.data.forEach(e => this.formatResSheet(e));
            return res.data;
        });
    },
    findAllForCurrentUser(filter) {
        return Api().get("/linksheets/currentuser", {params: removeEmptyParams(filter)}).then(res => {
            res.data.forEach(e => this.formatResSheet(e));
            return res.data;
        });
    },
    findAllCurrentWithRows(filter) {
        return Api().get("/linksheets/withrows", {params: removeEmptyParams(filter)}).then(res => {
            res.data.forEach(e => this.formatResDTO(e));
            return res.data;
        });
    },
    findAllCurrentValidatedWithRows(filter) {
        return Api().get("/linksheets/validated/withrows", {params: removeEmptyParams(filter)}).then(res => {
            res.data.forEach(e => this.formatResDTO(e));
            return res.data;
        });
    },
    findAllByYearWithRows(year, filter) {
        return Api().get("/linksheets/withrows/year/" + year, {params: removeEmptyParams(filter)}).then(res => {
            res.data.forEach(e => this.formatResDTO(e));
            return res.data;
        });
    },
    findAllByYearValidatedWithRows(year, filter) {
        return Api().get("/linksheets/validated/withrows/year/" + year, {params: removeEmptyParams(filter)}).then(res => {
            res.data.forEach(e => this.formatResDTO(e));
            return res.data;
        });
    },
    findAllYears() {
        return Api().get("/linksheets/years/").then(res => res.data);
    },
    findById(id) {
        return Api().get("/linksheet/" + id).then(res => this.formatResSheet(res.data));
    },
    findByIdWithRowsForView(id) {
        return Api().get("/linksheet/" + id + "/withrows/view").then(res => this.formatResDTO(res.data));
    },
    findByIdWithRowsForEdit(id) {
        return Api().get("/linksheet/" + id + "/withrows/edit").then(res => this.formatResDTO(res.data));
    },
    findCurrentYear() {
        return Api().get("/linksheet/currentyear").then(res => res.data);
    },
    create(linkSheetDTO) {
        linkSheetDTO = this.formatRequestDTO(linkSheetDTO);
        return Api().post("/linksheet", linkSheetDTO).then(res => this.formatResDTO(res.data));
    },
    update(linkSheetDTO) {
        linkSheetDTO = this.formatRequestDTO(linkSheetDTO);
        return Api().put("/linksheet/" + linkSheetDTO.linkSheet.id, linkSheetDTO).then(res => this.formatResDTO(res.data));
    },
    delete(linksheet) {
        return Api().delete("/linksheet/" + linksheet.id).then(() => linksheet);
    },
    download(linksheet) {
        return Api().get("/pdfreport/linksheet/" + linksheet.id, {responseType: 'blob'}).then(res => downloadData(res));
    },
    
    /*
     * FORMAT RESPONSE DATA
     */
     
    formatResDTO(d) {
        d.linkSheet = this.formatResSheet(d.linkSheet);
        d.linkRows.forEach(r => this.formatResRow(r))
        return d;
    },
    formatResRow(r) {
        if (r.alertHour) r.alertHour = this.formatResHour(r.alertHour);
        if (r.startHour) r.startHour = this.formatResHour(r.startHour);
        if (r.endHour) r.endHour = this.formatResHour(r.endHour);
        return r;
    },
    formatResSheet(e) {
        if (e.date) e.date = new Date(e.date);
        if (e.dateCreated) e.dateCreated = new Date(e.dateCreated);
        if (e.dateUpdated) e.dateUpdated = new Date(e.dateUpdated);
        if (e.dateValidated) e.dateValidated = new Date(e.dateValidated);
        e.status = this.statusFor(e);
        return e;
    },
    statusFor(e) {
        if (e.year !== null) return "ARCHIVED";
        if (e.dateValidated) return "VALIDATED";
        return "REDACTION";
    },
    formatRequestDTO(e) {
        e.linkRows.forEach(r => {
            this.formatRequestRow(r);
        })
        return e;
    },
    formatRequestRow(r) {
        if (r.startHour) r.startHour = this.formatRequestHour(r.startHour);
        if (r.endHour) r.endHour = this.formatRequestHour(r.endHour);
        if (r.alertHour) r.alertHour = this.formatRequestHour(r.alertHour);
        return r;
    },
    formatRequestHour(hour) {
        let date = new Date(Date.now());
        date.setHours(hour.split(':')[0], hour.split(':')[1], 0, 0);
        return date.getTime();
    },
    formatResHour(hour) {
        let h = moment(new Date(hour)).format("HH:mm");
        if (h.startsWith("0")) h = h.substring(1);
        return h;
    },
    deleteList(data) {
        return Api().delete("/linksheets", {data: data});
    }
}